import React from 'react'
import Layout from '../../components/layout'
import Helmet from 'react-helmet'
import { groupBy, values, flatten } from 'lodash'
import styled from 'styled-components'

import {
  Col,
  Container,
  Responsive,
  Row,
  Section,
} from '../../components/containers/Layout'
import {
  FinePrint,
  H2,
  H3,
  Heading,
  Paragraph,
} from '../../components/base/Typography'
import { Link } from 'gatsby'
import Button from '../../components/base/Button'
import MealCard from '../../components/meal/MealCard'
import bl1 from '../../media/brooke-lark-96398-unsplash.jpg'
import jp from '../../media/jennifer-pallian-306915-unsplash.jpg'
import j from '../../media/photo-1528992761365-34a53564c222.jpeg'
import breakfast from '../../media/alexander-mils-365917-unsplash.jpg'
import { List, ListItem } from '../../components/containers/List'
import { Card } from '../../components/base/Card'
import Polaroid, { Photo } from '../../components/base/Polaroid'
import hotdog from '../../media/hotdogs.jpeg'
import {
  faChartLine,
  faPlug,
  faGrinHearts,
  faGrinStars,
  faSearch,
  faUserAstronaut,
  faSearchDollar,
  faExclamationCircle,
  faHeart,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../../components/Contact'
import { BREAKFAST_RESPONSE } from '../../data/api-responses'
import { RecognitionExample } from '../food-recognition'

import { Feature } from '../../components/base/Feature'
import { StackedIcon } from '../../components/base/StackedIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ic0 from '../../media/ice-cream-bubbles.jpeg'
import waffleConeStrawberryVanillaYellowBackground from '../../media/ice-cream-1.jpeg'
import waffleCone2ScoopsPinkBackground from '../../media/ice-cream-2.jpeg'
import waffleConeStrawberryVanillaOutside from '../../media/ice-cream-3.jpeg'
import waffleConeVanillaGirlHolding from '../../media/ice-cream-7.jpeg'
import waffleConeStrawberryVanillaWatchHand from '../../media/ice-cream-5.jpeg'
import waffleConeChocolateVanillaPinkBackground from '../../media/ice-cream-6.jpeg'
import waffleCones3Hands from '../../media/ice-cream-4.jpeg'
import waffleConeKid from '../../media/ice-cream-8.jpeg'
import waffleConePistachio from '../../media/ice-cream-9.jpeg'
import waffleConeStrawberryVanillaMan from '../../media/ice-cream-10.jpeg'
import ic11 from '../../media/ice-cream-chocolate-covered.jpeg'
import ic12 from '../../media/ice-cream-chocolate-vanilla.jpeg'
import icePopWatermelon from '../../media/ice-cream-pop-watermelon.jpeg'
import icePopCoated from '../../media/ice-cream-pop.jpeg'
import icePopRaspberry from '../../media/ice-pop-berry.jpeg'
import icePopBerry from '../../media/fruit-pop-berry.jpeg'
import icePopCherryVanilla from '../../media/ice-pop-cherry-vanilla.jpeg'
import icePopCherry from '../../media/cherry-ice-pop.jpeg'
import frozenYogurt from '../../media/frozen-yogurt.jpeg'
import icePopRedWhiteBlueOutside from '../../media/ice-pop-color.jpeg'
import iceCreamConeBlack from '../../media/little-damage.jpeg'
import icePopPeach from '../../media/peach-ice-pop.jpeg'
import icePopYogurt from '../../media/ice-pop-yogurt.jpeg'
import icePopCookie from '../../media/ice-pop-cookie.jpeg'
import icePopSwirl from '../../media/ice-pop-0.jpeg'

import icePopChocolate from '../../media/ice-cream-pop-4.jpeg'

import waffleConeVanilla from '../../media/ice-cream-waffle-cone.jpeg'
import waffleConeHandBlurryBackground from '../../media/ice-cream-waffle-cone-1.jpeg'
import waffleConeSprinkles from '../../media/ice-cream-waffle-cone-2.jpeg'
import waffleConeKidHand from '../../media/ice-cream-waffle-cone-3.jpeg'
import waffleConeChocolateVanillaPlasticSpoon from '../../media/ice-cream-waffle-cone-4.jpeg'
import waffleConeGirlGlasses from '../../media/ice-cream-waffle-cone-5.jpeg'
import waffleConeGuyGlasses from '../../media/ice-cream-waffle-cone-6.jpeg'

import softServeStreet from '../../media/ice-cream-swirl-0.jpeg'
import softServeWhiteAndBlue from '../../media/ice-cream-swirl-1.jpeg'
import softServeGreen from '../../media/ice-cream-swirl-2.jpeg'
import softServeChocolateVanillaBoardwalk from '../../media/ice-cream-swirl-3.jpeg'
import softServeChocolateVanilla from '../../media/ice-cream-swirl-4.jpeg'
import softServeBeach from '../../media/ice-cream-swirl-5.jpeg'
import softServePurple from '../../media/ice-cream-swirl-6.jpeg'

import iceCreamScoopPistachio from '../../media/ice-cream-cup-0.jpeg'
import iceCreamScoopsChocolateVanillaPistachio from '../../media/ice-cream-cup-1.jpeg'
import iceCreamScoopBerry from '../../media/ice-cream-cup-2.jpeg'
import iceCreamScoopChocolate from '../../media/ice-cream-cup-3.jpeg'
import iceCreamScoopsWithStrawberries from '../../media/ice-cream-pop-3.jpeg'

import iceCreamSandwich from '../../media/ice-cream-sandwich-0.jpeg'
import iceCreamSandwichCereal from '../../media/ice-cream-sandwich-1.jpeg'
import iceCreamSandwichesStacked from '../../media/ice-cream-sandwich-2.jpeg'
import iceCreamSandwichCookie from '../../media/ice-cream-sandwich-3.jpeg'
import iceCreamSandwichBerry from '../../media/ice-cream-sandwich-4.jpeg'

import frape from '../../media/frap.jpeg'
import icedCoffeeCream from '../../media/iced-coffee-cream.jpeg'
import icedCoffeeStarbucks from '../../media/iced-coffee.jpeg'
import mocha from '../../media/mocha.jpeg'
import milkshake from '../../media/milkshake.jpeg'
import icedCoffeeStirring from '../../media/iced-coffee-1.jpeg'

import milkshakeChocolateIceCream from '../../media/ice-cream-milkshake-2.jpeg'
import crumbleIceCream from '../../media/ice-cream-crumble.jpeg'
import brownieIceCream from '../../media/ice-cream-cake.jpeg'

import selfie from '../../media/selfie.jpeg'

import { Flipper, Flipped } from 'react-flip-toolkit'
import Tag from '../../components/Tag'

const shuffle = items => {
  const a = [...items]
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const clusters = {
  icePops: [
    icePopPeach,
    icePopRaspberry,
    icePopYogurt,
    icePopCherryVanilla,
    icePopBerry,
    icePopCherry,
    icePopChocolate,
  ].map(x => ({ src: x, cluster: 'icePop' })),
  waffle: [
    waffleConeVanilla,
    waffleConeStrawberryVanillaYellowBackground,
    waffleCone2ScoopsPinkBackground,
    waffleConeChocolateVanillaPinkBackground,
    waffleConeChocolateVanillaPlasticSpoon,
    waffleConeGirlGlasses,
    waffleConeHandBlurryBackground,
    waffleConeKid,
    waffleConeKidHand,
    waffleConePistachio,
    waffleCones3Hands,
    waffleConeSprinkles,
    waffleConeStrawberryVanillaMan,
    waffleConeStrawberryVanillaOutside,
    waffleConeStrawberryVanillaWatchHand,
    waffleConeGuyGlasses,
  ].map(x => ({ src: x, cluster: 'waffle' })),
  drinks: [
    frape,
    icedCoffeeCream,
    mocha,
    icedCoffeeStirring,
    milkshake,
    milkshakeChocolateIceCream,
    icedCoffeeStarbucks,
  ].map(x => ({ src: x, cluster: 'drink' })),
  softServe: [
    softServeBeach,
    softServeChocolateVanilla,
    softServeChocolateVanillaBoardwalk,
    softServeGreen,
    softServePurple,
    softServeStreet,
    softServeWhiteAndBlue,
  ].map(x => ({ src: x, cluster: 'softServe' })),
  sandwich: [
    iceCreamSandwich,
    iceCreamSandwichBerry,
    iceCreamSandwichCereal,
    iceCreamSandwichCookie,
    iceCreamSandwichesStacked,
  ].map(x => ({ src: x, cluster: 'sandwich' })),
  scoops: [
    iceCreamScoopBerry,
    iceCreamScoopChocolate,
    iceCreamScoopPistachio,
    iceCreamScoopsChocolateVanillaPistachio,
    iceCreamScoopsWithStrawberries,
  ].map(x => ({ src: x, cluster: 'scoop' })),
  noise: [brownieIceCream, crumbleIceCream].map(x => ({
    src: x,
    cluster: 'noise',
  })),
}

const orderedClusters = [
  clusters.waffle,
  clusters.softServe,
  clusters.scoops,
  clusters.icePops,
  clusters.drinks,
  clusters.sandwich,
]

const filteredClusters = flatten(orderedClusters.map(c => c.slice(0, 6)))

const textResults = shuffle(flatten(filteredClusters))
const images = textResults

// ic1
const similarImages = [
  waffleConeVanilla,
  waffleConeGirlGlasses,
  waffleConeKidHand,
  waffleConeStrawberryVanillaYellowBackground,
  waffleConeChocolateVanillaPlasticSpoon,
  waffleConeSprinkles,
  waffleConeGuyGlasses,
  waffleCones3Hands,
  waffleConeVanillaGirlHolding,
  waffleConeStrawberryVanillaOutside,
  waffleConeKid,
  waffleConeHandBlurryBackground,
  softServeWhiteAndBlue,
  waffleConePistachio,
  waffleConeChocolateVanillaPinkBackground,
  waffleCone2ScoopsPinkBackground,
].map(x => ({ src: x }))
// ...clusters.waffle,
// ...clusters.softServe
// { src: waffleConeStrawberryVanillaOutside, cluster: 0, keywords: [], titile: '' },
// { src: waffleConeStrawberryVanillaWatchHand, cluster: 0, keywords: [], titile: '' },
// { src: waffleConeStrawberryVanillaMan, cluster: 0, keywords: [], titile: '' },

/**
 *
 * # TODO:
 *   - food image stats (social media)
 *   - ambiguity
 *   - expanding search index with missing keywords
 */

const SearchBar = ({ query }) => (
  <Card
    lifted
    style={{
      maxWidth: '100%',
      width: 300,
      borderRadius: 10,
      margin: 10,
      marginBottom: 25,
    }}
  >
    <div
      style={{
        padding: '20px 30px',
        borderBottom: '1px solid #EEE',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/*<FinePrint bold>Search</FinePrint>*/}
      <H3
        style={{
          borderRight: '2px solid lightblue',
          display: 'inline',
          paddingRight: 2,
          minWidth: 'auto',
        }}
      >
        {query}
      </H3>

      <FontAwesomeIcon icon={faSearch} />
    </div>
  </Card>
)

const Option = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 4px;
  background-color: ${({ active }) => (active ? '#EEF' : undefined)};
  opacity: ${({ active }) => (active ? 1 : 0.6)};

  &:hover {
    opacity: 1;
  }
`

class SearchSection extends React.Component {
  state = {
    activeExample: Demo.EXAMPLES.TEXT_SEARCH,
  }
  render() {
    return (
      <Section style={{ backgroundColor: 'white' }}>
        <Container
          wide
          reverse={this.props.reverse}
          style={{ alignItems: 'flex-start' }}
        >
          <Col style={{ flex: 2 }}>
            <Demo images={images} example={this.state.activeExample} />
          </Col>
          <Col style={{ flex: 1 }}>
            <Option
              active={this.state.activeExample === Demo.EXAMPLES.TEXT_SEARCH}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.TEXT_SEARCH })
              }
            >
              <H2>Food Search Is Hard</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Dish names, ingredients, flavors and synonyms make food search a
                difficult problem.
              </Paragraph>
            </Option>

            <Option
              active={this.state.activeExample === Demo.EXAMPLES.CLUSTERS}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.CLUSTERS })
              }
            >
              <H2>Group Results By Content</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Cluster results by visual similarity to make it easier to browse
                the results.
              </Paragraph>
            </Option>

            <Option
              active={this.state.activeExample === Demo.EXAMPLES.SIM_SEARCH}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.SIM_SEARCH })
              }
            >
              <H2>Search By Image</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Help users find exactly what they're looking for by letting them
                search by image.
              </Paragraph>
            </Option>

            <Option
              active={this.state.activeExample === Demo.EXAMPLES.EXPAND_INDEX}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.EXPAND_INDEX })
              }
            >
              <H2>Enhance Your Index</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Use image recognition, visual similarity and our food ontology
                to expand your index with more relevant metadata.
              </Paragraph>
            </Option>
          </Col>
        </Container>
      </Section>
    )
  }
}

class Demo extends React.Component {
  static EXAMPLES = {
    TEXT_SEARCH: 'TEXT_SEARCH',
    SIM_SEARCH: 'SIM_SEARCH',
    CLUSTERS: 'CLUSTERS',
    CONTENT_RECOMMENDATIONS: 'CONTENT_RECOMMENDATIONS',
    CONTENT_MODERATION: 'CONTENT_MODERATION',
    EXPAND_INDEX: 'EXPAND_INDEX',
    DATA_ENTRY: 'DATA_ENTRY',
    ORGANIZE: 'ORGANIZE',
  }

  static defaultProps = {
    // example: Demo.EXAMPLES.SIM_SEARCH,
    images: [],
    resultSize: 120,
    querySize: 160,
    simSearchResults: similarImages,
  }

  get imageClusters() {
    return orderedClusters
  }

  render() {
    let content
    switch (this.props.example || this.state.example) {
      case Demo.EXAMPLES.SIM_SEARCH:
        content = this.renderSimSearch()
        break
      case Demo.EXAMPLES.CLUSTERS:
        content = this.renderClusters()
        break
      case Demo.EXAMPLES.CONTENT_RECOMMENDATIONS:
        content = this.renderContentRecommendations()
        break
      case Demo.EXAMPLES.CONTENT_MODERATION:
        content = this.renderContentModeration()
        break
      case Demo.EXAMPLES.EXPAND_INDEX:
        content = this.renderExpandIndex()
        break
      case Demo.EXAMPLES.DATA_ENTRY:
        content = this.renderDataEntry()
        break
      case Demo.EXAMPLES.ORGANIZE:
        content = this.renderOrganize()
        break
      default:
        content = this.renderTextSearch()
    }

    return (
      <Flipper flipKey={this.props.example}>
        <div>{content}</div>
      </Flipper>
    )
  }

  renderTextSearch() {
    return (
      <div>
        <div>
          <Flipped flipId={'query'}>
            <SearchBar query={'Ice Cream'} />
          </Flipped>
        </div>
        {textResults.slice(0, 16).map(this.renderImage)}
      </div>
    )
  }

  renderSimSearch() {
    return (
      <div>
        <div>
          <Flipped flipId={'query'}>
            {this._renderImage(similarImages[0], 180)}
          </Flipped>
        </div>

        {similarImages
          // .slice(1, 8)
          .filter(img => img.src !== similarImages[0].src)
          .map(this.renderImage)}
      </div>
    )
  }

  renderExpandIndex() {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Flipped flipId={'query'}>
            {this._renderImage(similarImages[0], 280)}
          </Flipped>
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 10 }}>
              <FinePrint bold>Dish Name</FinePrint>
              <H3>Nana's Rich Vanilla</H3>
            </div>

            <div style={{ marginBottom: 10 }}>
              <FinePrint bold>Recognition Results</FinePrint>
              <Tag style={{ margin: 3 }} score={1}>
                Ice Cream
              </Tag>
              <Tag style={{ margin: 3 }} score={0.8}>
                Ice Cream Cone
              </Tag>
              <Tag style={{ margin: 3 }} score={0.7}>
                Vanilla
              </Tag>
              <Tag style={{ margin: 3 }} score={0.7}>
                Waffle Cone
              </Tag>
              <Tag style={{ margin: 3 }} score={0.6}>
                Dessert
              </Tag>
              <Tag style={{ margin: 3 }} score={0.5}>
                Gelato
              </Tag>
            </div>

            <div style={{ marginBottom: 10 }}>
              <FinePrint bold>Visually Similar Dish Names</FinePrint>
              <H3 style={{ fontWeight: 500 }}>Malted Vanilla Ice Cream</H3>
              <H3 style={{ fontWeight: 500, opacity: 0.8 }}>
                Sugar Waffle Cone
              </H3>
              <H3 style={{ fontWeight: 500, opacity: 0.6 }}>
                Vanilla Chocolate Chunk
              </H3>
              <H3 style={{ fontWeight: 500, opacity: 0.4 }}>Milk Con Leche</H3>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDataEntry() {
    return (
      <div style={{ padding: 30 }}>
        <H2
          style={{
            margin: 8,
            paddingBottom: 10,
            borderBottom: '1px solid #EEE',
          }}
        >
          Add New Item
        </H2>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Flipped flipId={'query'}>
            {this._renderImage(similarImages[0], 280)}
          </Flipped>
          <div style={{ padding: 30 }}>
            {/*<H3*/}
            {/*style={{*/}
            {/*marginBottom: 16,*/}
            {/*paddingBottom: 10,*/}
            {/*borderBottom: '1px solid #EEE',*/}
            {/*}}*/}
            {/*>*/}
            {/*Dish Details*/}
            {/*</H3>*/}
            <div style={{ marginBottom: 22 }}>
              <FinePrint bold>Dish Name</FinePrint>
              <H3
                style={{
                  border: '1px solid #EEE',
                  padding: '4px 6px',
                  borderRadius: 6,
                  backgroundColor: '#FAFAFA',
                }}
              >
                Nana's Rich Vanilla
              </H3>
            </div>

            <div style={{ marginBottom: 10 }}>
              <FinePrint bold>Suggested Keywords</FinePrint>
              <Tag style={{ margin: 3 }} selected>
                Ice Cream
              </Tag>
              <Tag style={{ margin: 3 }}>Ice Cream Cone</Tag>
              <Tag style={{ margin: 3 }} selected>
                Vanilla
              </Tag>
              <Tag style={{ margin: 3 }}>Cookie</Tag>
              <Tag style={{ margin: 3 }} selected>
                Waffle Cone
              </Tag>
              <Tag style={{ margin: 3 }}>Dessert</Tag>
              <Tag style={{ margin: 3 }}>Gelato</Tag>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContentRecommendations() {
    return (
      <div>
        <div>
          <Flipped flipId={'query'} />
          {this._renderImage(clusters.waffle[0], 180)}
        </div>
        <Flipped flipId={'results'}>
          <h3 style={{ margin: 10 }}>You Might Also Like</h3>
        </Flipped>

        {[
          clusters.waffle[2],
          clusters.waffle[5],
          clusters.waffle[1],
          clusters.waffle[8],
        ].map(this.renderImage)}
      </div>
    )
  }

  renderContentModeration() {
    return (
      <div>
        <div style={{ padding: 30 }}>
          <H2
            style={{
              margin: 8,
              // paddingBottom: 10,
              // borderBottom: '1px solid #EEE',
            }}
          >
            Your Order
          </H2>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid #EEE',
              padding: '10px 16px',
              borderRadius: 6,
            }}
          >
            <Flipped flipId={'query'}>
              {this._renderImage(similarImages[0], 80)}
            </Flipped>
            <div style={{ padding: 10 }}>
              <H3 style={{ fontWeight: 500 }}>Nana's Rich Vanilla</H3>
              <H3 style={{ fontWeight: 400 }}>$6.99</H3>
            </div>
          </div>

          <H2
            style={{
              margin: 8,
              // paddingBottom: 10,
              // borderBottom: '1px solid #EEE',
            }}
          >
            Review
          </H2>

          <div
            style={{
              marginBottom: 10,
              flex: 1,
              border: '1px solid #EEE',
              padding: '10px 16px',
              borderRadius: 6,
            }}
          >
            <div style={{ margin: '12px 10px' }}>
              <FontAwesomeIcon icon={faStar} color={'gold'} />
              <FontAwesomeIcon icon={faStar} color={'#EEE'} />
              <FontAwesomeIcon icon={faStar} color={'#EEE'} />
              <FontAwesomeIcon icon={faStar} color={'#EEE'} />
              <FontAwesomeIcon icon={faStar} color={'#EEE'} />

              {/*<FinePrint inline style={{marginLeft: 10}}>Select Rating</FinePrint>*/}
            </div>

            <div
              style={{
                flex: 1,
                display: 'block',
                border: '1px solid #EEE',
                padding: '10px 16px',
                borderRadius: 6,
                backgroundColor: '#FAFAFA',
                position: 'relative',
              }}
            >
              {this._renderImage({ src: selfie }, 120)}

              <div
                style={{
                  fontSize: '1rem',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                <H3 inline style={{ color: '#AAA' }}>
                  Add Your Photos Here
                </H3>
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              border: '1px solid #EEE',
              padding: '10px 16px',
              borderRadius: 6,
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={'red'}
              size={'2x'}
            />

            <H3 inline style={{ marginLeft: 12 }}>
              Image Does Not Appear To Match Item
            </H3>
          </div>
        </div>
      </div>
    )
  }

  renderOrganize() {
    return (
      <div>
        <H2
          style={{
            margin: '2px 8px',
            fontSize: '1.2rem',
            // paddingBottom: 10,
            // borderBottom: '1px solid #EEE',
          }}
        >
          Ice Cream, Waffle Cone, Vanilla
        </H2>
        <div key={1} style={{ marginBottom: this.props.resultSize / 3 }}>
          {clusters.waffle.slice(0, 4).map(this.renderImage)}
        </div>

        <H2
          style={{
            margin: '2px 8px',
            fontSize: '1.2rem',
            // paddingBottom: 10,
            // borderBottom: '1px solid #EEE',
          }}
        >
          Beverage, Coffee
        </H2>
        <div key={2} style={{ marginBottom: this.props.resultSize / 3 }}>
          {clusters.drinks.slice(0, 4).map(this.renderImage)}
        </div>

        <H2
          style={{
            margin: '2px 8px',
            fontSize: '1.2rem',
            // paddingBottom: 10,
            // borderBottom: '1px solid #EEE',
          }}
        >
          Ice Pop, Ice Cream, Fruit
        </H2>
        <div key={3} style={{ marginBottom: this.props.resultSize / 3 }}>
          {clusters.icePops.slice(0, 4).map(this.renderImage)}
        </div>
      </div>
    )
  }

  renderClusters() {
    return (
      <div>
        <div>
          <Flipped flipId={'query'}>
            <SearchBar query={'Ice Cream'} />
          </Flipped>
        </div>
        {this.imageClusters.map(this.renderCluster)}
      </div>
    )
  }

  renderCluster = (images, index) => {
    return (
      <div key={index} style={{ marginBottom: this.props.resultSize / 3 }}>
        {images.slice(0, 5).map(this.renderImage)}
      </div>
    )
  }

  renderImage = image => this._renderImage(image)

  _renderImage = (image, size = this.props.resultSize) => {
    return (
      <Flipped flipId={image.src} key={image.src}>
        <span style={{ display: 'inline-block' }}>
          <Polaroid
            style={{ margin: 6 }}
            src={image.src}
            size={size}
            // size={this.state.flipKey % 2 === 1 ? 160 : 100}
          />
        </span>
      </Flipped>
    )
  }
}

const Hero = () => (
  <Section
    style={{
      paddingBottom: 200,
      paddingTop: 100,
    }}
  >
    <Container wide>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {/*<img src={logo} height={120}/>*/}
        <Heading
          large
          align={'left'}
          style={{ marginTop: 20, marginBottom: 20, fontSize: '2.2rem' }}
        >
          Improve Search <br />
          And Discovery
        </Heading>
        <Paragraph align={'left'} narrow>
          Help users find exactly what they're looking for with image based
          search and semantic similarity.
        </Paragraph>
        <Link to="/request-demo">
          <Button size={'xl'} style={{ margin: '20px 0' }}>
            Book A Demo
          </Button>
        </Link>
      </Col>
      <Col className={'sm-full md-full'} wrap="no-wrap">
        <Responsive>
          {({ width } = {}) => (
            <div style={{ width: width < 500 ? 410 : 500 }}>
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeStrawberryVanillaMan}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleCone2ScoopsPinkBackground}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeStrawberryVanillaOutside}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeVanillaGirlHolding}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeStrawberryVanillaWatchHand}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeChocolateVanillaPinkBackground}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleCones3Hands}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConeKid}
                size={width < 500 ? 120 : 140}
              />
              <Polaroid
                style={{ margin: width < 500 ? 4 : 6 }}
                src={waffleConePistachio}
                size={width < 500 ? 120 : 140}
              />
            </div>
          )}
        </Responsive>
      </Col>
    </Container>
  </Section>
)

const rest = (
  <div>
    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Determine Dish Similarity</H2>
      <Paragraph narrow>
        Automatically learns to recognize previously submitted meals and
        improves as you use it.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Group Images By Dish</H2>
      <Paragraph narrow>
        Automatically learns to recognize previously submitted meals and
        improves as you use it.
      </Paragraph>
    </Col>
    <Col>
      <StackedIcon icon={faPlug} size={3} style={{ marginBottom: 20 }} />
      <H2>Show More With Fuzzy Results</H2>
      <Paragraph narrow>
        Show most similar results in your database even if you don't have
        exactly what they're looking for.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Improve Your Text Index</H2>
      <Paragraph narrow>
        Expand your text search index with keywords from most similar dishes to
        improve fuzzy matching on plain text queries.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Detect Outliers</H2>
      <Paragraph narrow>
        Expand your text search index with keywords from most similar dishes to
        improve fuzzy matching on plain text queries.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Generate Keywords</H2>
      <Paragraph narrow>
        Expand your text search index with keywords from most similar dishes to
        improve fuzzy matching on plain text queries.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Understand User Intent</H2>
      <Paragraph narrow>
        See exactly what your users are looking for to understand how to expand
        your catalogue.
      </Paragraph>
    </Col>

    <Col>
      <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
      <H2>Group Images By Content</H2>
      <Paragraph narrow>
        See exactly what your users are looking for to understand how to expand
        your catalogue.
      </Paragraph>
    </Col>
  </div>
)

const SDK = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col>
        <H2>Easy To Integrate</H2>
        <Paragraph narrow>
          Integrate into your Android, iOS and Web app in just a few hours with
          our SDK.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Learns to Recognize Past Meals</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Refine Results</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Uses Context</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
        </List>
        <Link to={'/request-demo'}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link>
      </Col>
      <Row className={'sm-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {size => (
              <Polaroid
                src={hotdog}
                size={size ? Math.max(250, Math.min(300, size.width / 3)) : 300}
              />
            )}
          </Responsive>
        </div>
      </Row>
    </Container>
  </Section>
)

const Benefits = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container wide reverse={reverse} style={{ alignItems: 'flex-start' }}>
      <Col>
        <StackedIcon
          icon={faSearchDollar}
          color={'gold'}
          backgroundColor={'lightyellow'}
          size={3}
          style={{ marginBottom: 20 }}
        />
        <H2>Improve Relevance</H2>
        <Paragraph narrow>
          Use machine learning to improve search result relevance for text and
          image based content.
        </Paragraph>
      </Col>

      <Col>
        <StackedIcon
          icon={faUserAstronaut}
          color={'gold'}
          backgroundColor={'lightyellow'}
          size={3}
          style={{ marginBottom: 20 }}
        />
        <H2>Understand Users</H2>
        <Paragraph narrow>
          Get a better picture of your users by seeing exactly what they're
          looking for.
        </Paragraph>
      </Col>

      <Col>
        <StackedIcon
          icon={faGrinStars}
          color={'gold'}
          backgroundColor={'lightyellow'}
          size={3}
          style={{ marginBottom: 20 }}
        />
        <H2>Boost Engagement</H2>
        <Paragraph narrow>
          Provide a more immersive image based experience.
        </Paragraph>
      </Col>
    </Container>
  </Section>
)

class ContentOrganization extends React.Component {
  state = {
    activeExample: Demo.EXAMPLES.ORGANIZE,
  }
  render() {
    return (
      <Section style={{ backgroundColor: 'white' }}>
        <Container
          wide
          reverse={this.props.reverse}
          style={{ alignItems: 'flex-start' }}
        >
          <Col style={{ flex: 2 }}>
            <Demo images={images} example={this.state.activeExample} />
          </Col>
          <Col style={{ flex: 1 }}>

            <Option
              active={this.state.activeExample === Demo.EXAMPLES.ORGANIZE}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.ORGANIZE })
              }
            >
              <H2>Organize Unlabeled Content</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Organize image galleries by recognized content and visual similarity.
              </Paragraph>
            </Option>

            <Option
              active={
                this.state.activeExample ===
                Demo.EXAMPLES.CONTENT_RECOMMENDATIONS
              }
              onClick={() =>
                this.setState({
                  activeExample: Demo.EXAMPLES.CONTENT_RECOMMENDATIONS,
                })
              }
            >
              <H2>Recommend Similar Items</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Use semantic similarity to provide relevant recommendaitons for
                any item in your database.
              </Paragraph>
            </Option>

            <Option
              active={this.state.activeExample === Demo.EXAMPLES.DATA_ENTRY}
              onClick={() =>
                this.setState({ activeExample: Demo.EXAMPLES.DATA_ENTRY })
              }
            >
              <H2>Automate Data Entry</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Help users provide more accurate information about their content
                by automating data entry with machine learning.
              </Paragraph>
            </Option>

            <Option
              active={
                this.state.activeExample === Demo.EXAMPLES.CONTENT_MODERATION
              }
              onClick={() =>
                this.setState({
                  activeExample: Demo.EXAMPLES.CONTENT_MODERATION,
                })
              }
            >
              <H2>Moderate User Submitted Content</H2>
              <Paragraph narrow style={{ marginBottom: 0 }}>
                Use computer vision to validate user submitted content.
              </Paragraph>
            </Option>
          </Col>
        </Container>
      </Section>
    )
  }
}

const VisualSearchPage = () => (
  <Layout>
    <Helmet
      title={'Bite AI - Food Logging SDK'}
      meta={[
        {
          name: 'description',
          content: 'Food Recognition API',
        },
        {
          property: 'og:description',
          content: 'Food Recognition API',
        },
        {
          name: 'keywords',
          content:
            'food recognition, api, deep learning, image recognition,' +
            'food recognition api, food search, food image recognition,' +
            ' food knowledge graph',
        },
      ]}
    />
    <Hero />

    <SearchSection />

    {/*<Section>*/}
    {/*<Container>*/}
    {/*<Demo images={shuffle(images)} />*/}
    {/*</Container>*/}
    {/*</Section>*/}

    {/*<VisualSearchSection />*/}
    <Benefits />

    <ContentOrganization />

    <Contact product={'Food Logging SDK'} />
  </Layout>
)

export default VisualSearchPage
